// TODO this is a NGG.component component, but with the addition of a "disabled" props

import { Button } from '@ngg/components';
import { cn } from '@ngg/storefront-utils';
import UnderlyingItems from '@ui/components/cart/components/UnderlyingItems';
import Link from '@ui/components/Link/Link';
import Paragraph from '@ui/components/Paragraph/Paragraph';
import StockStatus from '@ui/components/StockStatus/StockStatus';
import { STOCK_LIMIT } from '@utils/cart/constants';
import { getStockStatusDictionary } from '@utils/stockStatus/getStockStatusDictionary';
import getStockStatusType from '@utils/stockStatus/getStockStatusType';
import { ReactNode } from 'react';

export type CartItemProps = {
  onRemove?: () => void;
  disabled?: boolean;
  title: string;
  brand: string;
  ActionComponent?: ReactNode;
  TitleComponent?: ReactNode;
  dictionary: any;
  classNames?: {
    wrapper?: string;
    itemTitle?: string;
    titleWrapper?: string;
    imageWrapper?: string;
    textWrapper?: string;
  };
  ImageComponent: ReactNode;
  StockStatusText?: ReactNode;
  item?: any;
  closeMenu?: () => void;
};

/**
 *
 * @param props
 * @constructor
 */
export default function CartItem(props: CartItemProps) {
  const {
    onRemove,
    disabled,
    title,
    brand,
    ActionComponent = null,
    dictionary,
    classNames,
    ImageComponent,
    TitleComponent,
    StockStatusText,
    item,
    closeMenu,
  } = props;

  return (
    <div
      className={cn([
        'border-gray-2 grid min-h-[104px] grid-cols-[103px,_minmax(0,1fr)] items-start border-b',
        classNames?.wrapper,
      ])}>
      <div
        className={cn([
          'border-gray-150 flex aspect-square h-full w-full items-center justify-center bg-beige',
          classNames?.imageWrapper,
        ])}>
        {ImageComponent}
      </div>
      <div
        className={cn(['mx-3 mb-3 pt-2 font-sans', classNames?.textWrapper])}>
        {onRemove ? (
          <div className="flex items-center justify-between">
            <Paragraph className="max-w-36 truncate font-sans text-xs font-bold uppercase sm:max-w-72">
              {brand}
            </Paragraph>
            <Button
              type="button"
              className="w-fit min-w-10 gap-0 self-start border-0 bg-inherit p-0 text-black underline"
              onClick={onRemove}
              disabled={disabled}>
              {dictionary?.remove}
            </Button>
          </div>
        ) : (
          <Paragraph className="truncate text-left text-xs font-bold uppercase">
            {brand}
          </Paragraph>
        )}
        {TitleComponent ?? (
          <div className={cn(['flex text-sm', classNames?.titleWrapper])}>
            {item?.extras?.product_url ? (
              <Link
                onClick={() => closeMenu && closeMenu()}
                href={item?.extras?.product_url}
                className={cn(['truncate text-left', classNames?.itemTitle])}>
                {title}
              </Link>
            ) : (
              <Paragraph
                className={cn(['truncate text-left', classNames?.itemTitle])}>
                {title}
              </Paragraph>
            )}
          </div>
        )}
        {ActionComponent}
        {item?.underlyingItems && (
          <UnderlyingItems items={item?.underlyingItems} />
        )}
        {StockStatusText && (
          <StockStatus
            className="mt-1 font-serif text-xs leading-none"
            iconSize={8}
            stockStatusDictionary={getStockStatusDictionary(dictionary || {})}
            status={getStockStatusType([item?.stockStatus?.statusCode])}
            stockDetails={{
              deliveryMin: item?.stockStatus?.deliveryTime?.from?.toString(),
              deliveryMax: item?.stockStatus?.deliveryTime?.to?.toString(),
              deliveryUnit: item?.stockStatus?.deliveryTime?.unit?.toString(),
              stockQuantity: item?.stockStatus?.quantity,
              stockLimit: STOCK_LIMIT,
            }}
          />
        )}
      </div>
    </div>
  );
}
