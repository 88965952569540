export const FEATURE_FLAGS = {
  mobile: {
    showNewCustomerDiscountPopup: isFeatureEnabled(
      process.env.NEXT_PUBLIC_FEATURES_MOBILE_NEW_CUSTOMER_DISCOUNT_POPUP,
    ),
  },
  cache: {
    loop: isFeatureEnabled(process.env.NEXT_PUBLIC_FEATURES_CACHE_LOOP),
  },
  explodeVariants: {
    search: isFeatureEnabled(
      process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_SEARCH,
    ),
    products: isFeatureEnabled(
      process.env.NEXT_PUBLIC_FEATURES_EXPLODE_VARIANTS_PRODUCTS,
    ),
  },
  checkout: {
    walley: isFeatureEnabled(process.env.NEXT_PUBLIC_FEATURES_CHECKOUT_WALLEY),
  },
} as const;

function isFeatureEnabled(value?: string) {
  const compare = value?.localeCompare('true', undefined, {
    sensitivity: 'accent',
  });

  return compare === 0;
}
