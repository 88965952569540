import {
  CartFragment,
  CategoryProductFragment,
  GetCartAddressQuery,
  ProductFragment,
  ProductServiceProduct,
} from '@business/gql/graphql';
import { Product } from '@ts/product';
import { Variant } from '@ts/variant';
import { Maybe } from 'graphql/jsutils/Maybe';
import { CartMutationArgs } from './hooks/cart/useMutateCart';
import { PageGTMInfo } from '@ui/components/Product/ProductSearch';

declare global {
  interface Window {
    dataLayer: any;
  }
}

function ensureDataLayer() {
  if (typeof window === undefined) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
}

/* NG specification of events to send: 
https://docs.google.com/spreadsheets/d/1LF-1mvz6OTaRjQ27jIcBW8VlKL55nU3cqc2q23g71mQ/edit#gid=0 */

/* GA4 Docs, recommended parameters of events: 
https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=6817894073281423404-EU&client_type=gtag#add_to_cart */

/* Klarna checkout events docs: 
https://docs.klarna.com/klarna-checkout/in-depth-knowledge/client-side-events/#id-kzqbu0jd8nd-at-and-de */

export function gTagViewCart(cart: Maybe<CartFragment>) {
  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];
    const cartItems = cart?.items
      ?.filter(
        (item) =>
          item?.partNo !==
          `${process.env.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
      )
      ?.map((item, index) => ({
        item_id: item?.partNo,
        item_name: item.name,
        discount: item.isDiscounted ? item.priceOriginal - item.price : 0,
        index,
        item_brand: item.brand,
        price: item.price,
        quantity: item.quantity,
      }));

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: cart?.currencyCode,
        value: cart?.totalGrossAmount,
        items: cartItems,
      },
    });
  }
}

// TODO: Should addition of quantity in cart trigger gTagAddToCart?
export function gTagAddToCart(
  variables: CartMutationArgs['items'],
  cart: CartFragment | null,
) {
  if (typeof window !== undefined) {
    const partNo = variables?.map((item) => item.partNo);

    const cartItem = cart?.items?.filter((item) =>
      partNo.includes(item.partNo),
    );

    const items = cartItem?.map((item) => ({
      item_id: item?.partNo,
      item_name: item?.name,
      currency: cart?.currencyCode,
      item_brand: item?.brand,
      price: item?.price,
      quantity: item?.quantity,
      item_variant: item?.id,
    }));

    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: cart?.currencyCode,
        value: cart?.totalGrossAmount,
        items: items,
      },
    });
  }
}

// TODO: Should removal of quantity in cart trigger gTagRemoveFromCart?
export function gTagRemoveFromCart(partNo: string, cart?: CartFragment | null) {
  if (typeof window !== undefined) {
    const cartItem = cart?.items?.find((item) => item.partNo === partNo);
    window.dataLayer = window.dataLayer || [];
    const discount =
      cartItem?.priceOriginal && cartItem?.price
        ? cartItem.priceOriginal - cartItem.price
        : 0;

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: cart?.currencyCode,
        value: cartItem?.totalGrossAmount,
        items: [
          {
            item_id: cartItem?.partNo,
            item_name: cartItem?.name,
            currency: cart?.currencyCode,
            discount,
            item_brand: cartItem?.brand,
            price: cartItem?.price,
            quantity: 0,
          },
        ],
      },
    });
  }
}

export function gTagSelectListItem(
  product:
    | Product
    | ProductServiceProduct
    | ProductFragment
    | CategoryProductFragment
    | undefined,
  currency: string,
  gtmInfo?: PageGTMInfo,
) {
  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];

    const { item_list_id, item_list_name } = gtmInfo ?? {};

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        item_list_id,
        item_list_name,
        items: [
          {
            item_id: product?.id,
            item_name: product?.title,
            currency,
            item_brand: product?.brand,
            item_category:
              product && 'category' in product ? product?.category : '',
            // TODO: Add full category path (item_category2, item_category3)
            // item_category: product?.['CategoryPath.Name']?.[0],
            // item_category2: product?.['CategoryPath.Name']?.[1],
            // item_category3: product?.['CategoryPath.Name']?.[2],
            item_list_id,
            item_list_name,
            price: product?.price,
          },
        ],
      },
    });
  }
}

export function gTagViewItem(
  variant: Variant | undefined,
  product: Product,
  currency: string,
) {
  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];

    // Make sure view_item event is sent only once per product/variant view.
    // Return if view_item event for same variant exists in datalayer.
    // const foundViewItemEvent = window.dataLayer.find(
    //     (el: any) => el?.event === 'view_item',
    // );
    // const selectValue = variant ?? product;
    // const foundSameId =
    //     foundViewItemEvent?.ecommerce?.items?.[0]?.item_id === selectValue?.id;

    // Make sure view_item event is sent only once per product/variant view.
    // Return if view_item event for same variant exists in datalayer.
    // TODO: This probably need to be copied back to/bug-fixed in NO-GA as well.
    const foundViewItemEvents = window.dataLayer.filter(
      (el: any) => el?.event === 'view_item',
    );
    const selectValue = variant ?? product;
    const foundSameId = foundViewItemEvents.some((event: any) => {
      return event?.ecommerce?.items?.[0]?.item_id === selectValue?.id;
    });

    if (foundSameId) {
      return;
    }
    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }
    if (!variant) {
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency,
          value: product.price,
          items: [
            {
              item_id: product.id,
              item_name: product.title,
              currency,
              item_brand: product?.brand,
              item_category: product?.category,
              // TODO: Add full category path (item_category2, item_category3)
              // item_category: product['CategoryPath.Name']?.[0],
              // item_category2: product['CategoryPath.Name']?.[1],
              // item_category3: product['CategoryPath.Name']?.[2],
              price: product.price,
              item_variant: product.id,
            },
          ],
        },
      });
    } else {
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency,
          value: variant.price,
          items: [
            {
              item_id: variant.id,
              item_name: variant.title,
              currency,
              item_brand: product.brand,
              item_category: product?.category,
              // TODO: Add full category path (item_category2, item_category3)
              // item_category: product['CategoryPath.Name']?.[0],
              // item_category2: product['CategoryPath.Name']?.[1],
              // item_category3: product['CategoryPath.Name']?.[2],
              price: variant.price,
              item_variant: product.id,
            },
          ],
        },
      });
    }
  }
}

export function gTagBeginCheckout(cart?: CartFragment | null) {
  if (!cart) return;

  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];

    const foundBeginCheckoutEvent = window?.dataLayer?.find(
      (el: any) => el?.event === 'begin_checkout',
    );

    // Return if begin_checkout event exists in datalayer.
    if (foundBeginCheckoutEvent) {
      return;
    }

    const cartItems = cart?.items
      ?.filter(
        (item) =>
          item?.partNo !==
          `${process.env.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
      )
      ?.map((item, index) => ({
        item_id: item?.partNo,
        item_name: item?.name,
        currency: cart?.currencyCode,
        discount:
          item?.isDiscounted && item?.priceOriginal && item?.price
            ? item.priceOriginal - item.price
            : 0,
        index,
        item_brand: item?.brand,
        price: item?.price,
        quantity: item?.quantity,
      }));

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: cart?.currencyCode,
        value: cart?.totalGrossAmount,
        coupon: cart?.discountCode,
        items: cartItems,
      },
    });
  }
}

export function gTagViewItemList(
  items: Maybe<ProductFragment | CategoryProductFragment>[],
  currency: string,
  gtmInfo: PageGTMInfo,
) {
  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];
    const { item_list_name, item_list_id } = gtmInfo;

    const listItems = items?.map((item, index) => ({
      item_id: item?.id,
      item_name: item?.title,
      currency,
      index,
      item_brand: item?.brand,
      item_category: item && 'category' in item ? item?.category : '',
      // TODO: Add full category path (item_category2, item_category3)
      // item_category: item?.['CategoryPath.Name']?.[0],
      // item_category2: item?.['CategoryPath.Name']?.[1],
      // item_category3: item?.['CategoryPath.Name']?.[2],
      item_list_name,
      price: item?.price,
    }));

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        item_list_id,
        item_list_name,
        items: listItems,
      },
    });
  }
}

export function gTagAddPaymentInfo(cart?: CartFragment) {
  if (!cart) return;
  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];

    const cartItems = cart?.items
      ?.filter(
        (item) =>
          item?.partNo !==
          `${process.env.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
      )
      ?.map((item, index) => ({
        item_id: item?.partNo,
        item_name: item?.name,
        discount:
          item?.isDiscounted && item?.priceOriginal && item?.price
            ? item.priceOriginal - item.price
            : 0,
        index,
        item_brand: item?.brand,
        price: item?.price,
        quantity: item?.quantity,
      }));

    const cartDiscountCodes =
      cart?.basketPromotions
        ?.map((promotion) => promotion.discountCode)
        .flat(2)
        .toString() ?? '';

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }
    window.dataLayer.push({
      event: 'add_payment_info', // Klarna 'billing_address_change'-event
      ecommerce: {
        currency: cart?.currencyCode,
        value: cart?.totalGrossAmount,
        coupon: cartDiscountCodes,
        items: cartItems,
      },
    });
  }
}

// TODO: add shipping_tier to add_shipping_info if possible
export function gTagAddShippingInfo(cart?: CartFragment) {
  if (!cart) return;
  if (typeof window !== undefined) {
    window.dataLayer = window.dataLayer || [];

    const cartItems = cart?.items
      ?.filter(
        (item) =>
          item?.partNo !==
          `${process.env.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
      )
      ?.map((item, index) => ({
        item_id: item?.partNo,
        item_name: item?.name,
        discount:
          item?.isDiscounted && item?.priceOriginal && item?.price
            ? item.priceOriginal - item.price
            : 0,
        index,
        item_brand: item?.brand,
        price: item?.price,
        quantity: item?.quantity,
      }));
    const cartDiscountCodes =
      cart?.basketPromotions
        ?.map((promotion) => promotion?.discountCode)
        .flat(2)
        .toString() ?? '';

    if (window.dataLayer.length) {
      window.dataLayer.push({ ecommerce: null });
    }

    window.dataLayer.push({
      event: 'add_shipping_info', // Klarna 'shipping_address_change'-event.
      ecommerce: {
        currency: cart?.currencyCode,
        value: cart?.totalGrossAmount,
        coupon: cartDiscountCodes,
        shipping_tier: cart.meta?.NSHIFT_SHIPPING_TYPE ?? '',
        items: cartItems,
      },
    });
  }
}

export function gTagPurchase({
  cart,
  cartAddress,
}: {
  cart: CartFragment;
  cartAddress?: GetCartAddressQuery['cartAddress'];
}) {
  if (typeof window === undefined) {
    return;
  }
  ensureDataLayer();

  const cartDiscountCodes =
    cart?.basketPromotions
      ?.map((promotion) => promotion.discountCode)
      .flat(2)
      .toString() ?? '';

  const cartItems = cart?.items
    ?.filter(
      (item) =>
        item?.partNo !==
        `${process.env.NEXT_PUBLIC_NSHIFT_DYNAMIC_FREIGHT_PARTNO}`,
    )
    ?.map((item, index) => {
      const discount =
        item.isDiscounted && item.priceOriginal && item.price
          ? item.priceOriginal - item.price
          : 0;

      return {
        index,
        item_id: item.partNo,
        item_name: item.name,
        discount,
        item_brand: item.brand,
        price: item.price,
        quantity: item.quantity,
      };
    });

  if (window.dataLayer.length) {
    window.dataLayer.push({ ecommerce: null });
  }

  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: cart.id,
      currency: cart.currencyCode,
      value: cart.totalGrossAmount,
      tax: cart.totalVat,
      shipping: cart.totalFreightInclVat,
      coupon: cartDiscountCodes,
      items: cartItems,
      enhanced_conversion_data: cartAddress && {
        email: cartAddress.customerDataSellTo.email,
        phone_number: cartAddress.customerDataSellTo.cellPhoneNumber,
        first_name: cartAddress.customerDataSellTo.firstName,
        last_name: cartAddress.customerDataSellTo.lastName,
        street: cartAddress.customerDataSellTo.street,
        city: cartAddress.customerDataSellTo.city,
        region: cartAddress.customerDataSellTo.region,
        postal_code: cartAddress.customerDataSellTo.postalCode,
        country: cartAddress.customerDataSellTo.country,
      },
    },
  });
}
