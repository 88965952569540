export const language = process.env.NEXT_PUBLIC_APP_LANGUAGE;
export const market = process.env.NEXT_PUBLIC_APP_MARKET;
export const locale = process.env.NEXT_PUBLIC_APP_LOCALE;
export const brandCode = 'LM';

const config: {
  language: string;
  market: string;
  locale: string;
  brandCode: string;
} = {
  language,
  market,
  locale,
  brandCode,
};

export default config;
